export type ConfigDomain =
  | "delivery"
  | "pdv"
  | "relatório"
  | "impressão"
  | "financeiro"
  | "fiscal"
  | "adquirência";

export interface ConfigType {
  name: string;
  description: string;
  type: "boolean" | "number" | "token";
  existInApp: boolean;
  domain: ConfigDomain;
}

export const ConfigsLibrary: ConfigType[] = [
  {
    name: "aceitar_pedido_automaticamente",
    description: "Aceite automático de pedidos",
    type: "boolean",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "delivery_ifood_menu",
    description:
      "Sincronização de status entre os produtos do cardápio Yooga <> iFood que possuem o mesmo código PDV",
    type: "number",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "delivery_ifood_shipping",
    description: "Entrega fácil",
    type: "number",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "delivery_ifood_widget",
    description:
      "Widget do iFood. Ele permite utilizar algumas funções do iFood diretamente pelo App. São elas: Chat com o cliente, notificações, acordo de cancelamento e rastreamento de pedidos",
    type: "number",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "delivery_sequential_count",
    description: "Numeração sequencial dos pedidos",
    type: "number",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "delivery_ifood_not_finish",
    description:
      "Após a conclusão do pedido no iFood, ele permanecerá ativo no App, sendo necessário finaliza-lo manualmente",
    type: "number",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "delivery_ifood_additional_value",
    description:
      "No relatório de vendas por data, exibe informações de taxas, acréscimos e subsídios das vendas realizadas no iFood",
    type: "token",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "modulo_fidelidade",
    description: "Módulo de fidelidade",
    type: "number",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "delivery_ifood_additional_value_payment",
    description:
      "Transforma o valor subsidiado pelo iFood em pagamento na NFC-e",
    type: "number",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "delivery_ifood_menu_full",
    description:
      "Integração de cardápio completa entre Yooga <> iFood. Permite criar produtos diretamente na Yooga e estes serão replicados no iFood, entre outras ações",
    type: "boolean",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "delivery_to_idi",
    description:
      "Gestor de pedidos compartilhado. Ele encaminha os pedidos de um App para outro",
    type: "boolean",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "modulo_cardapios",
    description:
      "Exibe múltiplos cardápios, atualmente apenas a franquia da ATW faz uso",
    type: "boolean",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "delivery_generate_sell_nfce",
    description: "Botão para impressão da NFC-e na tela do pedido",
    type: "number",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "delivery_step_generation",
    description:
      "Define em qual etapa o sistema irá gerar a venda de um pedido do Delivery. Por padrão, a venda é gerada ao despachar o pedido mas pode ser alterada com os seguintes valores: DELIVERED = Ao despachar, FINISHED = Ao finalizar ou PREPARING = Ao preparar",
    type: "token",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "modulo_documento_obrigatorio",
    description:
      "Torna o CPF obrigatório para realizar um pedido no Delivery. Config criada para contemplar as regras fiscais da região Sul do Brasil",
    type: "boolean",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "feature_toggle_produto_agendamento",
    description: "Habilita o cardápio de somente agendamento no delivery",
    type: "number",
    existInApp: false,
    domain: "delivery",
  },
  {
    name: "modulo_ficha",
    description:
      "Módulo ficha. Realiza a impressão de produtos determinados em formato de ficha, idêntico ao que ocorre em bares e eventos",
    type: "number",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "modulo_senha_pedido",
    description: "Senha nos pedidos do balcão",
    type: "number",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "kds_enabled",
    description: "Módulo KDS",
    type: "number",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "group_products_table",
    description:
      "Agrupamento de produtos na mesa. Substitui a exibição de lista por agrupamento númerico, exemplo: Água - Água para 2 - Água",
    type: "boolean",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "yooga_whatsapp_enabled",
    description: "Habilita a ativação do robô do whatsapp",
    type: "boolean",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "yooga_whatsapp_greeting_message",
    description:
      "Habilita a mensagem de saudação automática do robô do whatsapp",
    type: "number",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "yooga_whatsapp_instance",
    description: "Módulo do robô do whatsapp",
    type: "token",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "modulo_atacado",
    description:
      "Configuração específica do módulo balcão. Habilita o campo de quantidade e valor para atacado, o valor será praticado quando atingir a quantidade configurada",
    type: "boolean",
    existInApp: true,
    domain: "pdv",
  },
  {
    name: "change_valor_venda",
    description:
      "Permite alterar a quantidade e valor do produto adicionado no carrinho, clicando no ícone da quantidade",
    type: "number",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "disable_edit_menu",
    description:
      "Bloqueia as seguintes funções no App: Edição de Categoria, Produto, Complemento e Receita em todos os cardápios (PDV, Delivery e Digital). Editar área de entrega.",
    type: "boolean",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "keep_search_table",
    description:
      "Configuração específica do módulo mesas. Mantém o conteúdo digitado na barra de pesquisa da mesa.",
    type: "number",
    existInApp: true,
    domain: "pdv",
  },
  {
    name: "modulo_saldo_caixa",
    description:
      "Habilita a visualização das informações do caixa ao nível de permissão Vendedor + Caixa",
    type: "number",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "modulo_pagamento_v2",
    description: "Unificação das formas de pagamento",
    type: "number",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "modulo_editar_receitas",
    description:
      "Habilita a opção de remover um item da receita do um produto selecionado. O item removido será impresso de forma tachada",
    type: "number",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "modulo_senha",
    description:
      "Habilita a necessidade de inserir uma senha para cancelar uma venda. A senha é o valor contido nesta configuração",
    type: "token",
    existInApp: true,
    domain: "pdv",
  },
  {
    name: "modulo_lancamento_individual",
    description:
      "Habilita o botão de retorno dos produtos para aba de vendas em aberto. Geralmente utilizado pela franquia Kibon",
    type: "number",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "modulo_retorno_salva_estoque",
    description:
      "Habilita o botão de retorno dos produtos ao estoque. Geralmente utilizado pela franquia Kibon",
    type: "number",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "fidelity_v2",
    description: "Habilita o módulo fidelidade no balcão e mesas",
    type: "number",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "enable_vero",
    description:
      "Configuração específica do módulo balcão. Habilita a integração com a POS da Vero",
    type: "number",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "modulo_videosoft",
    description: "Habilita a integração com a Videosoft",
    type: "number",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "token_videosoft",
    description: "Token de integração enviado pela Videosoft",
    type: "token",
    existInApp: false,
    domain: "pdv",
  },
  {
    name: "modulo_vendedores",
    description:
      "Habilita o relatório de vendas por vendedor e a seleção dos vendedores ao final de cada venda",
    type: "number",
    existInApp: false,
    domain: "relatório",
  },
  {
    name: "modulo_relatorio_acrescimo",
    description: "Habilita o relatório de acréscimo",
    type: "number",
    existInApp: false,
    domain: "relatório",
  },
  {
    name: "modulo_perda",
    description:
      "Habilita o relatório e o lançamento de perdas no estoque dos produtos",
    type: "boolean",
    existInApp: false,
    domain: "relatório",
  },
  {
    name: "online_payments_report",
    description: "Habilita o relatório de Pagamentos Online",
    type: "number",
    existInApp: false,
    domain: "relatório",
  },
  {
    name: "remove_ifood_report",
    description:
      "Mantém o valor dos cupons do iFood no relatório de pedidos do delivery",
    type: "number",
    existInApp: false,
    domain: "relatório",
  },
  {
    name: "print_acrescimo_label",
    description: "Habilita a edição do nome 'acréscimo' na DAV",
    type: "token",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "desabilitar_impressoes",
    description:
      "Configuração específica do módulo balcão. Remove o botão de imprimir do carrinho",
    type: "boolean",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "delivery_nao_imprimir_automaticamente",
    description:
      "Configuração específica do módulo delivery. Desabilita a impressão automática ao aceitar o pedido",
    type: "boolean",
    existInApp: true,
    domain: "impressão",
  },
  {
    name: "imprimir_somente_finalizar",
    description:
      "Substitui a impressão da DAV/NFC-e pela via de produção(categoria de impressão) ao finalizar a venda",
    type: "number",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "mostra_observacao",
    description: "A DAV é impressa com as observações inseridas no produto",
    type: "number",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "modulo_print_produtos_fechamento",
    description:
      "Imprime a relação de produtos vendidos após o fechamento do caixa",
    type: "number",
    existInApp: true,
    domain: "impressão",
  },
  {
    name: "modulo_imprimir_complementos",
    description:
      "Imprime os complementos ao invés do produto na via de produção(categoria de impressão)",
    type: "boolean",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "modulo_categorias_disable_cart",
    description:
      "Configuração específica do módulo balcão. Desabilita a impressão da DAV ao clicar no ícone da impressora no carrinho",
    type: "boolean",
    existInApp: true,
    domain: "impressão",
  },
  {
    name: "imprimir_nfce_opcao",
    description: "",
    type: "boolean",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "config_texto_final_impressao",
    description:
      "Habilita a inserção de um texto personalizado no rodapé da DAV",
    type: "token",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "imprimir_estoque_atual",
    description:
      "Habilita a impressão do relatório de produtos vendidos no caixa aberto atual",
    type: "boolean",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "imprime_segunda_via_dav",
    description: "Imprime uma via DAV na impressora descrita",
    type: "token",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "feature_toggle_printer_page",
    description: "Habilita a nova tela de configuração das impressões no App",
    type: "boolean",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "imprimir_categoria_finalizar",
    description:
      "Configuração específica do módulo balcão. Imprime a categoria de impressão ao finalizar a venda",
    type: "boolean",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "emissor_print_margin_right",
    description:
      "Configuração específica do Emissor. Ajusta o tamanho da margem direita",
    type: "token",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "emissor_print_margin_left",
    description:
      "Configuração específica do Emissor. Ajusta o tamanho da margem esquerda",
    type: "token",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "feature_toggle_local_impressao_delivery",
    description:
      "Configuração específica do módulo delivery. Habilita a categoria de impressão",
    type: "boolean",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "modulo_impressao_v2",
    description: "Habilita a fila de impressão",
    type: "boolean",
    existInApp: false,
    domain: "impressão",
  },
  {
    name: "modulo_contas_pagar",
    description:
      "Habilita o lançamento automático do fechamento de caixa no financeiro",
    type: "boolean",
    existInApp: false,
    domain: "financeiro",
  },
  {
    name: "financeiro_franquias",
    description: "Bloqueia a edição do financeiro",
    type: "number",
    existInApp: false,
    domain: "financeiro",
  },
  {
    name: "modulo_produto_gorjeta",
    description:
      "Transforma a gorjeta em um produto fiscal, sendo necessário o cadastro das informações fiscais e habilita o relatório de 'taxa de serviço'",
    type: "number",
    existInApp: false,
    domain: "fiscal",
  },
  {
    name: "modulo_robo",
    description: "Token de ativação do robô de notas",
    type: "token",
    existInApp: false,
    domain: "fiscal",
  },
  {
    name: "modulo_entrada_nota_v2",
    description:
      "Habilita a nova entrada de notas(v2), pode ser utilizada no App/Emissor. Ao ativar essa configuração, as notas que tiveram a entrada feita na v1 não estarão mais disponíveis para visualização, apenas as próximas que tiverem a entrada na v2.",
    type: "boolean",
    existInApp: false,
    domain: "fiscal",
  },
  {
    name: "modulo_codigo_pdv_fiscal",
    description:
      "Habilita o campo 'Código do Produto' dentro da aba fiscal, no cadastro de produtos. Se preenchido, será impresso na NFC-e no campo de código",
    type: "number",
    existInApp: false,
    domain: "fiscal",
  },
  {
    name: "modulo_nota_item",
    description:
      "Habilita a opção de 'Entrada Nota Item' no painel, é uma entrada de nota reduzida",
    type: "boolean",
    existInApp: false,
    domain: "fiscal",
  },
  {
    name: "fiscal_modulo_mfe",
    description: "Habilita o formulário de MF-e",
    type: "boolean",
    existInApp: false,
    domain: "fiscal",
  },
  {
    name: "stone_not_print_merchant_receipt",
    description:
      "Configuração específica da maquininha da Stone. Desabilita a impressão automática da 'Via do Lojista'",
    type: "number",
    existInApp: false,
    domain: "adquirência",
  },
  {
    name: "imprimir_nfce_maquininha",
    description:
      "Configuração específica da maquininha da Stone. Habilita a impressão da NFC-e",
    type: "boolean",
    existInApp: true,
    domain: "adquirência",
  },
  {
    name: "imprimir_dav_maquininha",
    description:
      "Configuração específica da maquininha da Stone. Habilita a impressão da DAV",
    type: "boolean",
    existInApp: true,
    domain: "adquirência",
  },
  {
    name: "feature_toggle_pos_tap",
    description: "Habilita o Tap to Pay (BETA)",
    type: "boolean",
    existInApp: false,
    domain: "adquirência",
  },
  {
    name: "pix_maquininha",
    description:
      "Configuração específica da maquininha da Stone. Habilita a geração do QR code do Pix",
    type: "boolean",
    existInApp: true,
    domain: "adquirência",
  },
  {
    name: "pix_on_table_enabled",
    description: "Habilita a geração do QR code do Pix no PDV (Balcão e Mesas)",
    type: "boolean",
    existInApp: false,
    domain: "adquirência",
  },
  {
    name: "cashback_mensalidade_pix",
    description:
      "Habilita a participação do programa de cashback para usuários do pix integrado. O cashback será para o nosso parceiro, não para o consumidor final",
    type: "boolean",
    existInApp: false,
    domain: "adquirência",
  },
  {
    name: "modulo_mercado_pago",
    description: "Habilita a autenticação do Mercado Pago no Painel(Dashboard)",
    type: "boolean",
    existInApp: false,
    domain: "adquirência",
  },
];
