import {
  Button,
  Card,
  CardBody,
  Checkbox,
  FormControl,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { disableContract } from "services/contract";
import { Mixpanel } from "services/mixpanel";
import { Contract } from "shared/models/contract";

interface DisableContractModalProps {
  isOpen: boolean;
  onClose(): void;
  contract: Contract;
}

const DisableContractModal: React.FC<DisableContractModalProps> = ({
  isOpen,
  onClose: onCloseFromProps,
  contract,
}) => {
  const { loadCustomer } = useCustomerContext();

  const [loading, setLoading] = useState(false);

  const [shouldCancelAllOpenOrders, setShouldCancelAllOpenOrders] =
    useState(false);

  const onClose = () => {
    onCloseFromProps();
    setLoading(false);
  };

  const submit = () => {
    Mixpanel.track("customers-financial-clicked-disable-contract");
    disableContract(contract.id as number, shouldCancelAllOpenOrders)
      .then(() => {
        toast.success("Contrato desativado com sucesso");
        loadCustomer();
      })
      .catch(() => toast.error("Não foi possível desativar o contrato"))
      .finally(() => onClose());
  };

  return (
    <Modal
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton isDisabled={loading} />
        <ModalHeader>
          Desativar contrato {contract.selectedPeriodicity}
        </ModalHeader>

        <ModalBody>
          <FormControl mt="1rem">
            <Stack spacing={1}>
              <Checkbox
                isDisabled={loading}
                onChange={() => setShouldCancelAllOpenOrders(true)}
              >
                Cancelar todas as cobranças abertas?
              </Checkbox>
            </Stack>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Stack w="100%">
            {shouldCancelAllOpenOrders === true && (
              <Card bg="red.400" color="white">
                <CardBody>
                  <HStack>
                    <Icon as={MdInfoOutline} />
                    <Text>
                      Atenção, o cancelamento da cobrança não reflete no boleto
                      faturado! Ele continuará ativo por até 90 dias.
                    </Text>
                  </HStack>
                </CardBody>
              </Card>
            )}
            <Button
              isLoading={loading}
              onClick={submit}
              colorScheme="yooga"
              w="100%"
            >
              Confirmar
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DisableContractModal;
