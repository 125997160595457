import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useGlobalContext } from "GlobalContext";
import moment from "moment";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { useState } from "react";
import { FaChevronDown, FaPlus, FaRegCircleStop } from "react-icons/fa6";
import { MdOutlineFolderDelete } from "react-icons/md";
import { TbReload } from "react-icons/tb";
import { toast } from "react-toastify";
import { deleteContract, enableContract } from "services/contract";
import { Mixpanel } from "services/mixpanel";
import { Contract } from "shared/models/contract";
import DisableContractModal from "../../components/DisableContractModal";

interface ActionsProps {
  contract: Contract;
}

const Actions: React.FC<ActionsProps> = ({ contract }) => {
  const { confirm } = useGlobalContext();
  const { openContractModal, loadCustomer } = useCustomerContext();

  const [disableContractModalOpen, setDisableContractModalOpen] =
    useState(false);

  const openDisableContractModal = () => setDisableContractModalOpen(true);

  const closeDisableContractModal = () => setDisableContractModalOpen(false);

  const handleToggleEnableContract = () => {
    const enableAndRenew =
      "Contrato expirado! Ao prosseguir com a reativação, ele será renovado gerando as cobranças automaticamente. Deseja continuar?";

    if (contract.status === 0)
      confirm(
        `Reativar contrato ${contract.selectedPeriodicity}`,
        moment(contract.expiration_date) >= moment()
          ? "Deseja continuar?"
          : enableAndRenew,
        () => {
          Mixpanel.track("customers-financial-clicked-enable-contract");
          enableContract(contract.id as number)
            .then(() => {
              toast.success("Contrato reativado com sucesso");
              loadCustomer();
            })
            .catch(() => toast.error("Não foi possível reativar o contrato"));
        }
      );
    else openDisableContractModal();
  };

  const handleDeleteContract = () => {
    confirm(
      `Apagar contrato ${contract.selectedPeriodicity}`,
      "Deseja continuar?",
      () => {
        Mixpanel.track("customers-financial-deleted-contract");
        deleteContract(contract.id as number)
          .then(() => {
            toast.success("Contrato apagado com sucesso");
            loadCustomer();
          })
          .catch(() => toast.error("Não foi possível apagar o contrato"));
      }
    );
  };

  const handleOpenAddServicesModal = () => {
    Mixpanel.track("customers-financial-add-services-opened-modal");
    openContractModal(contract);
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          alignSelf="flex-start"
          size="sm"
          rightIcon={<FaChevronDown />}
        >
          Ações
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={handleToggleEnableContract}
            icon={
              contract.status !== 0 ? (
                <FaRegCircleStop size="1rem" />
              ) : (
                <TbReload size="1.2rem" />
              )
            }
          >
            {contract.status !== 0 ? "Desativar" : "Reativar"} contrato
          </MenuItem>

          <MenuItem
            onClick={handleDeleteContract}
            icon={<MdOutlineFolderDelete size="1.2rem" />}
          >
            Apagar Contrato
          </MenuItem>

          <MenuItem onClick={handleOpenAddServicesModal} icon={<FaPlus />}>
            Adicionar Serviços
          </MenuItem>
        </MenuList>
      </Menu>

      {disableContractModalOpen && (
        <DisableContractModal
          isOpen={disableContractModalOpen}
          onClose={closeDisableContractModal}
          contract={contract}
        />
      )}
    </>
  );
};

export default Actions;
